<template>
  <div class="redirect">
    <empty emotion="wink">
      <h4>
        请使用微信扫码打开哦
      </h4>
      <div>
        <square src="https://img.xinshu.me/upload/3c05b7dd978f417e9bebff5d1686a98c" class="mx-auto"
                style="max-width: 160px;"/>
      </div>
    </empty>
  </div>
</template>

<script>
export default {
  name: 'workshop.redirect',
  created() {
    if (this.hasLogin) {
      this.$router.replace('/workshop')
    }
  }
}
</script>

<style scoped>

</style>
