<template>
  <div class="redeem">
    <h2 class="page-title">使用兑换码</h2>
    <b-card no-body>
      <square :src="hero" height="75%"/>
      <b-card-body>
        <form @submit.prevent="submit">
          <p>输入兑换码开始制作</p>

          <b-form-group>
            <b-input size="lg" placeholder="输入您获得的兑换码" v-model="code" maxlength="12" autofocus
                     style="text-transform: uppercase"/>
          </b-form-group>

          <b-btn block :disabled="!code || submitting" variant="primary" type="submit">
            <fa icon="spinner" spin v-if="submitting"/>
            确认兑换
          </b-btn>
        </form>
      </b-card-body>
    </b-card>

    <b-card class="mt-3">
      <b>使用规则</b>
      <ol class="pl-4 mb-0 mt-2">
        <li>礼品卡兑换码一旦使用不予以退换</li>
        <li>每个兑换码仅限兑换一本作品</li>
        <li>输入兑换码后，创建完成的，可在书架直接进入作品</li>
        <li>未创建完成的，需要下次再次输入兑换码创建作品</li>
      </ol>
    </b-card>
  </div>
</template>

<script>
import { chain } from 'lodash'
import route from '@/mixins/route-data'

export default {
  name: 'redeem',
  title: '使用兑换码',
  mixins: [route('albumProducts')],
  data() {
    return {
      code: this.$route.query.code || '',
      submitting: false
    }
  },
  computed: {
    hero() {
      let url = ''
      const tid = this.$route.query.tid
      if (tid) {
        url = chain(this.albumProducts).find({tid}).get('hero').value()
      }
      if (!url) {
        url = chain(this.albumProducts).find({tid: 'xcalbum'}).get('hero').value()
      }
      return url
    }
  },
  methods: {
    async submit() {
      try {
        this.submitting = true
        const code = this.code.toUpperCase()
        const result = await this.$req.post('/api/redeem_code/validate', {redeemCode: code})
        if (result.aid) {
          return this.$router.push('/albums/' + result.aid)
        }
        this.$router.push('/create/album?tid=' + result.tid + '&step=1&code=' + code)
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .redeem {
    max-width: 640px;
  }
</style>
