<template>
  <div class="workshop">
    <b-nav class="mb-3" tabs fill>
      <b-nav-item :active="tab === ''" @click="tab = ''" @click.shift="reset">已创建作品</b-nav-item>
      <b-nav-item :active="tab === 'orders'" @click="tab = 'orders'">已购买作品</b-nav-item>
    </b-nav>

    <loading v-if="loading" />
    <template v-else-if="!tab">
      <template v-if="albums.length">
        <div class="book" v-for="item in albums" :key="item.bookId">
          <book-item :value="item">
            <template slot="info">
              <div class="text-muted">
                {{item.pageCount}} 页
                /
                已传图 {{item.uploadedImagesCount}}
                /
                {{item.innerStyle.name}}
              </div>
              <datetime :value="item.createdAt" prefix="创建于 " class="text-muted"/>
            </template>

            <template slot="action" v-if="!item.locked">
              <b-btn :to="'/albums/' + item.aid">编辑</b-btn>
              <b-btn :to="'/books/' + item.bookId + '/buy'" variant="primary">购买</b-btn>
            </template>
          </book-item>
        </div>
      </template>
      <empty icon="book" class="py-5" v-else>没有已创建作品哦</empty>
      <div class="btn-area">
        <b-btn block to="/workshop/redeem">点击创建新作品</b-btn>
      </div>
    </template>
    <template v-else-if="tab === 'orders'">
      <template v-if="orders.length">
        <div class="book" v-for="order in orders" :key="order.id">
          <book-item :value="order.book">
            <template slot="info">
              <div>{{$rmb(order.paidMoney)}} / {{order.count}}套</div>
              <div>
                No.{{order.id}} |
                <datetime :value="order.createTime" format="YYYY.MM.DD"/>
              </div>
              <div v-if="order.shipNo">
                <fa icon="truck"/>
                {{order.shipCompany.slice(0, 2)}} {{order.shipNo}}
              </div>
              <div v-else>{{order.status | orderStatus}}</div>
              <div class="mt-2">
                <b-btn :to="'/orders/' + order.id">查看详情</b-btn>
                <b-btn variant="primary" :to="'/pay/' + order.id" v-if="!order.paid">付款</b-btn>
              </div>
            </template>
          </book-item>
        </div>
      </template>
      <empty v-else icon="credit-card">暂无订单</empty>
    </template>
  </div>
</template>

<script>
import { find } from 'lodash'

import route from '@/mixins/route-data'
import { studioProducts } from '@/config'

import BookItem from '@/pages/Book/BookItem'

export default {
  name: 'workshop',
  title: '我的作品集',
  components: {BookItem},
  mixins: [route('albums', {size: 96}), route('orders')],
  data() {
    return {
      creating: false,
      code: '',
      tab: this.$route.query.tab || ''
    }
  },
  methods: {
    onLoad() {
      const products = Object.keys(studioProducts)
      this.orders = this.orders.filter(i => products.includes(i.bookType)).filter(i => i.id.length > 8)
      this.albums = this.albums.filter(i => products.includes(i.tid)).filter(item => {
        return !find(this.orders, i => i.book.originalAid === item.aid)
      })
    },
    reset() {
      this.$ss.removeItem('source')
      location.href = '/books'
    },
    submit() {
      this.creating = true
      setTimeout(() => {
        this.code = ''
        this.creating = false
        this.$alert.success('作品已创建')
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .workshop {
    max-width: 640px;

    .book {
      padding: 0 1rem;
      border-bottom: 2px solid $hr-border-color;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
</style>
